<template>
  <SignInCompleteView />
</template>
<script>
import SignInCompleteView from '../../views/login/SignInComplete.vue'
export default {
  name: 'SignInComplete',
  components: {
    SignInCompleteView,
  },
  methods: {},
}
</script>
