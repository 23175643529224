<template>
  <div class="full-height d-flex flex-column">
    <div class="full-height d-flex flex-column align-center mt-40">
      <video
        ref="video"
        autoplay
        loop
        muted
        playsinline
        :poster="require('@/assets/images/white-bg.png')"
      >
        <source
          :src="require('@/assets/videos/signup-complete.mp4')"
          type="video/mp4"
        />
      </video>
      <span class="d-block mt-10 rixgo-bold font-g20-30"
        >셀룩 가입 완료</span
      >
      <span class="d-block font-g14-22 text-center px-10 mt-6"><span v-if="userName">{{ userName }}님 </span>셀룩에 오신것을 환영합니다. <br>
셀룩에서는 개인화 서비스로 사용자의 취향에 <br>
알맞은 상품을 추천해드립니다.</span>
    </div>

    <div class="btn-container">
    <div
      class=" btn font-g14-20 rixgo-bold blue white--text text-center"
      @click="moveToMainPage"
    >
      셀룩 시작하기!
    </div>
    </div>
  </div>
</template>
<!-- Naver Script -->
<script>
  const _nasa ={};
  if(window.wcs) _nasa['cnv'] = wcs.cnv("2","1");
</script>
<script>
import Vue from 'vue'
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'SignInCompleteView',
  computed :{
    ...mapGetters('UserStore',['userName'])
  },
  mounted() {
    this.$kakaoPixel('completeRegistration')
    this.$criteo({
      event: 'trackTransaction',
      id: Math.floor(Math.random() * 99999999999),
      item: [{ id: '1', price: 1, quantity: 1 }],
    })
    this.$tracking('af_complete_registration')
    try{
      // 모비온 스크립트
      document.querySelector('.mobionButton').click()
    }catch(e){
      console.log("mobion script error")
    }
  },
  methods: {
    moveToMainPage() {
      localStorage.removeItem('categoryHistory')

      //회원가입 완료 후 밸런스게임 이벤트 페이지로 다시 이동
      const eventHistory = sessionStorage.getItem('eventHistory')
      if(eventHistory){
        this.$router.push(eventHistory)
        sessionStorage.removeItem('eventHistory')
        return
      }

      this.$router.push('/')
    },
  },
})
</script>
<style scoped>
.btn-container {
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding: 20px;
  background: #FFFFFF;
  max-width: 600px;
}

.btn{
  cursor: pointer;
  border-radius: 4px;
  padding: 16px;
}

video{
  width: 160px;
  height: 160px;

  /* https://lunasoft.atlassian.net/jira/software/c/projects/CELLOOK/issues/CELLOOK-3776 */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
</style>
